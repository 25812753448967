<div class="header" fxLayout fxLayoutAlign="space-between center">
  <div class="img-wrapper">
    <a [ngStyle]="{'background-image': 'url(../../assets/img/ONECORE_logo_WHITE_RGB.svg)' }" class="img"
      fxFlexAlign="center" routerLink="/"></a>
  </div>
  <span (click)="showMenu = !showMenu" class="mobile-menu" fxHide.gt-xs fxLayout fxLayoutAlign="space-between center"
    fxShow>
    <app-icon icon="menu"></app-icon>
    <strong translate="layout.mobileMenu"></strong>
  </span>
</div>

<div [class.opened]="showMenu" class="menu">
  <app-user-menu (itemClicked)="showMenu = false"></app-user-menu>

  <app-navbar-item (click)="showMenu = false" *appPermissionRestrict="{requiredPermission: [CanManageSettings]}"
    icon="cogs" label="layout.menu.settings" link="/settings"></app-navbar-item>

  <app-navbar-item *appPermissionRestrict="{requiredPermission: [CanManageUsersOrgUnits]}" [submenu]="true" icon="users"
    label="layout.menu.usersOrganizations.title" link="/users-organizations">
    <ng-container subitems>
      <app-navbar-sub-item (click)="showMenu = false" label="layout.menu.usersOrganizations.organizationUnits"
        link="/users-organizations/organization-units"></app-navbar-sub-item>
      <app-navbar-sub-item (click)="showMenu = false" label="layout.menu.usersOrganizations.roleManagement"
        link="/users-organizations/role-management"></app-navbar-sub-item>
      <app-navbar-sub-item (click)="showMenu = false" label="layout.menu.usersOrganizations.users"
        link="/users-organizations/users"></app-navbar-sub-item>
      <app-navbar-sub-item (click)="showMenu = false" label="layout.menu.usersOrganizations.restrictionManagement"
        link="/users-organizations/restriction-management"></app-navbar-sub-item>
    </ng-container>
  </app-navbar-item>

  <app-navbar-item *appPermissionRestrict="{requiredPermission: [CanManageCatalogue]}" [submenu]="true" icon="car"
    label="layout.menu.cars" link="/cars">
    <ng-container subitems>
      <app-navbar-sub-item (click)="showMenu = false" label="layout.menu.carList"
        link="/cars/list"></app-navbar-sub-item>
      <app-navbar-sub-item (click)="showMenu = false" label="layout.menu.carParameters"
        link="/cars/parameters"></app-navbar-sub-item>
      <app-navbar-sub-item (click)="showMenu = false" label="layout.menu.carEquipment"
        link="/cars/equipment"></app-navbar-sub-item>
    </ng-container>
  </app-navbar-item>

  <app-navbar-item (click)="showMenu = false" *appPermissionRestrict="{requiredPermission: [CanManageInsurances]}"
    icon="shield" label="layout.menu.insurance" link="/insurance"></app-navbar-item>

  <app-navbar-item *appPermissionRestrict="{requiredPermission: [CanManageProductsServices]}" [submenu]="true"
    icon="tools" label="layout.menu.productConfiguration" link="/product-configuration">
    <ng-container subitems>
      <app-navbar-sub-item (click)="showMenu = false" label="layout.menu.productAndService.title"
        link="/product-configuration/product-and-service"></app-navbar-sub-item>
      <app-navbar-sub-item (click)="showMenu = false" label="layout.menu.priceList.title"
        link="/product-configuration/price-list"></app-navbar-sub-item>
      <app-navbar-sub-item (click)="showMenu = false" label="layout.menu.refi"
        link="/product-configuration/refi"></app-navbar-sub-item>
      <app-navbar-sub-item (click)="showMenu = false" label="layout.menu.pneuservis.title"
        link="/product-configuration/pneuservis"></app-navbar-sub-item>
    </ng-container>
  </app-navbar-item>

  <app-navbar-item (click)="showMenu = false" *appPermissionRestrict="{requiredPermission: [CanManageProvisions]}"
    icon="credit-card" label="layout.menu.provision" link="/provision"></app-navbar-item>
</div>